import BonusService from "@/services/bonus.service";

export default {
  namespaced: true,
  state: {
    bonuses: [],
    loading: false,
    countRow: 0
  },
  getters: {
    bonuses: state => state.bonuses,
    loading: state => state.loading,
    countRow: state => state.countRow
  },
  mutations: {
    setBonuses(state, data) {
      state.bonuses = data;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setCount(state, data) {
      state.countRow = data;
    }
  },
  actions: {
    findMany(store, params = {}) {
      store.commit("setLoading", true);
      BonusService.findMany(params).then(data => {
        store.commit("setBonuses", data);
        store.commit("setLoading", false);
      });
    },
    count(store, params = {}) {
      store.commit("setLoading", true);
      BonusService.count(params).then(data => {
        store.commit("setCount", data);
      });
    }
  }
};
