import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/pages/NotFoundPage";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/layout/DashboardLayout"),
    redirect: "/withdrawal",
    children: [
      {
        path: "/overview",
        name: "Статистика",
        component: () => import("@/views/pages/Overview")
      },
      {
        path: "/statistic",
        name: "Общая статистика",
        component: () => import("@/views/pages/Statistic/Index")
      },
      {
        path: "/wallets",
        name: "Локальные кошельки",
        component: () => import("@/views/pages/LocalWallet/Index")
      },
      {
        path: "/users",
        name: "Работа с пользователями",
        component: () => import("@/views/pages/Users/Index")
      },
      {
        path: "/users/:id",
        name: "Пользователь",
        component: () => import("@/views/pages/Users/Edit")
      },
      {
        path: "/support/tickets",
        name: "Поддержка",
        component: () => import("@/views/pages/Support/Index")
      },
      {
        path: "/support/tickets/:id",
        name: "Поддержка",
        component: () => import("@/views/pages/Support/View")
      },
      {
        path: "/Bonus",
        name: "Поддержка",
        component: () => import("@/views/pages/Bonus/Index")
      },
      {
        path: "/withdrawal",
        name: "Вывод средств",
        component: () => import("@/views/pages/Withdrawals/Index")
      },
      {
        path: "/payments/methods",
        name: "Платежные методы",
        component: () => import("@/views/pages/PaymentsMethod/Index")
      },
      {
        path: "/frod/ip/view/:ip",
        name: "Фрод контроль",
        component: () => import("@/views/pages/FrodControl/View")
      },
      {
        path: "/refferals",
        name: "Рефералы",
        component: () => import("@/views/pages/Referals/Index")
      }
    ]
  },
  { path: "*", name: "Страница не найдена", component: NotFound },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pages/Login")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters["user/auth"];

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
