<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  watch: {
    $route(to) {
      document.title = to.name;
    }
  },
  components: {},

  data: () => ({
    //
  })
};
</script>
