import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueNoty from "vuejs-noty";
import { interceptorsSetup } from "./instances/http";
import "vuejs-noty/dist/vuejs-noty.css";
import "chartist/dist/scss/chartist.scss";
import roles from "@/mixins/roles";
import Vue2Filters from "vue2-filters";
Vue.use(Vue2Filters);

Vue.config.productionTip = false;
Vue.use(VueNoty, {
  theme: "nest",
  timeout: 700,
  progressBar: true,
  layout: "bottomCenter",
  visibilityControl: true
});
Vue.use(require("vue-moment"));

interceptorsSetup();
Vue.mixin(roles);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
