export default {
  namespaced: true,
  state: {
    dialogs: {
      addWallet: {
        show: false,
        data: {}
      },
      sendMoney: {
        show: false,
        data: {}
      },
      DeleteWallet: {
        show: false,
        data: {}
      },
      helpDashboard: {
        show: false,
        data: {}
      },
      driverUploadStatus: {
        show: false,
        data: {}
      },
      driverVersionUpload: {
        show: false,
        data: {}
      },
      driverProducerInsert: {
        show: false,
        data: {}
      },
      driverModelInsert: {
        show: false,
        data: {}
      },
      directoryCreate: {
        show: false,
        data: {}
      },
      directoryUpdate: {
        show: false,
        data: {}
      },
      directoryDelete: {
        show: false,
        data: {}
      },
      proxyEditor: {
        show: false,
        data: {}
      },
      search: {
        show: false,
        data: {}
      }
    }
  },
  mutations: {
    dialog(state, data) {
      state.dialogs = { ...state.dialogs, ...data };
    },
    changeLoginRequired(state, loginRequired) {
      state.loginRequired = loginRequired;
    }
  },
  actions: {
    dialog(store, data) {
      store.commit("dialog", data);
    }
  }
};
