import { api } from "@/instances/http";

class BonusService {
  findMany(params) {
    return api.post("/bonus/findMany", { ...params }).then(response => {
      return response.data;
    });
  }
  count(params) {
    return api.post("/bonus/count", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params) {
    return api.post("/bonus/findUnique", { ...params }).then(response => {
      return response.data;
    });
  }
  findFirst(params) {
    return api.post("/bonus/findFirst", { ...params }).then(response => {
      return response.data;
    });
  }
  update(params) {
    return api.post("/bonus/update", { ...params }).then(response => {
      return response.data;
    });
  }
  create(params) {
    return api.post("/bonus/create", { ...params }).then(response => {
      return response.data;
    });
  }
  delete(params) {
    return api.post("/bonus/delete", { ...params }).then(response => {
      return response.data;
    });
  }
}

export default new BonusService();
