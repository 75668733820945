import axios from "axios";
import store from "../store";

export const api = axios.create({
  baseURL: "/admin"
});

export const interceptorsSetup = () => {
  api.interceptors.request.use(
    function(config) {
      const token = store.getters["user/token"];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function(err) {
      return Promise.reject(err);
    }
  );

  api.interceptors.response.use(
    response => response,
    error => {
      const status = error.response ? error.response.status : null;
      if (status === 401) {
        store.dispatch("user/logout");
      }
      return Promise.reject(error);
    }
  );
};
