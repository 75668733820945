import { api } from "@/instances/http";

class UserService {
  login(user) {
    return api
      .post("/auth/admin", {
        login: user.login,
        password: user.password,
        code: user.code
      })
      .then(response => {
        return response.data;
      });
  }

  getPlayerTokenByAdmin(params = {}) {
    return api.post("/auth/getPlayerTokenByAdmin", params).then(response => {
      return response.data;
    });
  }
}

export default new UserService();
