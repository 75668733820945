import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("user", {
      userForMixin: "user"
    })
  },
  methods: {
    ...mapActions("user", ["logout"]),
    checkRoles: function(roles) {
      if (!this.userForMixin.role) return false;
      return roles.includes(this.userForMixin.role.name);
    }
  },
  created() {
    if (!this.userForMixin.role) {
      this.logout();
    }
  }
};
