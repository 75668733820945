import UserService from "@/services/user.service";

export default {
  namespaced: true,
  state: {
    user: {},
    auth: false,
    token: "",
    id: null
  },
  getters: {
    user: state => state.user,
    auth: state => state.auth,
    token: state => state.token,
    getProfileAdmin(state) {
      return state.id;
    }
  },
  mutations: {
    setUserID(state, data) {
      state.id = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    setAuth(state, data) {
      state.auth = data;
    },
    setToken(state, data) {
      state.token = data;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    }
  },
  actions: {
    login(store, user) {
      return UserService.login(user).then(
        data => {
          store.commit("setUserID", data.user.id);
          store.commit("setUser", data.user);
          store.commit("setToken", data.token);
          store.commit("setAuth", true);
          return Promise.resolve(user);
        },
        error => {
          store.commit("setAuth", false);
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit("setAuth", false);
      commit("setToken", "");
      commit("setUser", {});
    }
  }
};
