import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import users from "./modules/users";
import bonuses from "./modules/bonuses";
import dialogs from "./modules/dialogs";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["user"]
    })
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    users,
    bonuses,
    dialogs
  }
});
