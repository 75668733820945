import UserService from "@/services/users.service";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    users: [],
    user: {},
    loading: false
  },
  getters: {
    users: state => state.users,
    user: state => state.user,
    loading: state => state.loading
  },
  mutations: {
    setUsers(state, data) {
      state.users = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    setLoading(state, data) {
      state.loading = data;
    }
  },
  actions: {
    findMany(store, params = {}) {
      store.commit("setLoading", true);
      return UserService.findMany(params).then(
        data => {
          store.commit("setUsers", data);
          store.commit("setLoading", false);
          return Promise.resolve(data);
        },
        error => {
          Vue.noty.error(error.response.data.message);
          return Promise.reject(error);
        }
      );
    },
    aggregate(store, params = {}) {
      store.commit("setLoading", true);
      return UserService.aggregate(params).then(
        data => {
          store.commit("setUsers", data);
          store.commit("setLoading", false);
          return Promise.resolve(data);
        },
        error => {
          Vue.noty.error(error.response.data.message);
          return Promise.reject(error);
        }
      );
    },
    count(store, params = {}) {
      store.commit("setLoading", true);
      return UserService.count(params).then(
        data => {
          store.commit("setUsers", data);
          store.commit("setLoading", false);
          return Promise.resolve(data);
        },
        error => {
          Vue.noty.error(error.response.data.message);
          return Promise.reject(error);
        }
      );
    },
    findUnique(store, params = {}) {
      store.commit("setLoading", true);
      return UserService.findUnique(params).then(
        data => {
          store.commit("setUser", data);
          store.commit("setLoading", false);
          return Promise.resolve(data);
        },
        error => {
          Vue.noty.error(error.response.data.message);
          return Promise.reject(error);
        }
      );
    },
    update(store, params = {}) {
      store.commit("setLoading", true);
      return UserService.update(params).then(
        data => {
          store.commit("setUser", data);
          store.commit("setLoading", false);
          return Promise.resolve(data);
        },
        error => {
          Vue.noty.error(error.response.data.message);
          return Promise.reject(error);
        }
      );
    },
    additional(store, params = {}) {
      store.commit("setLoading", true);
      return UserService.additional(params).then(
        data => {
          store.commit("setUser", data);
          store.commit("setLoading", false);
          return Promise.resolve(data);
        },
        error => {
          Vue.noty.error(error.response.data.message);
          return Promise.reject(error);
        }
      );
    }
  }
};
